const linechart2 = {
    "data": {
        "url": "data/cars.json"
    },
    "mark": "line",
    "encoding": {
        "x": {
            "field": "Year",
            "type": "temporal"
        }
    }
}

export default linechart2;